<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">安知课堂</a>
          <i>></i>
          <a href="javascript:;">安知公益</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div class="searchboxItem">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="retrievalData.courseName"
                type="text"
                size="small"
                style="width: auto"
                placeholder="请输入课程名称"
                clearable
              />
            </div>
            <div class="searchboxItem">
              <span class="itemLabel">发布状态:</span>
              <el-select
                v-model="retrievalData.welfareCourseStatus"
                placeholder="请选择发布状态"
                clearable
                filterable
                size="small"
              >
                <el-option
                  v-for="item in welfareCourseStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div>
              <el-button class="bgc-bv" type="primary" @click="getData()"
                >查询</el-button
              >
              <el-button class="bgc-bv" type="primary" @click="relatedCourses"
                >关联课程</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :header-cell-style="tableHeader"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
            >
              <el-table-column label="序号" align="center" type="index" />
              <el-table-column
                label="证书名称"
                align="center"
                prop="courseName"
                :show-overflow-tooltip="true"
              />
              <el-table-column
                label="状态"
                align="center"
                prop="welfareCourseStatus"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                      "PUBLIC_GOOD_STATUS",
                      scope.row.welfareCourseStatus
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="创建日期"
                align="center"
                width="140"
                prop="createTime"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="280px">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    @click="releaseRecovery(scope.row)"
                    >{{
                      scope.row.welfareCourseStatus == "10" ? "发布" : "回收"
                    }}</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    @click="deleteList(scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @getData="getData"
          @sizeChange="sizeChange"
        />
      </div>
    </div>
    <courseTableMore isMini ref="courseTableMore" @eventBus="courseTableMoreBack" />
  </div>
</template>

<script>
import List from "@/mixins/List";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import courseTableMore from "@/views/miniApp/excellentCourses/courseTable";
export default {
  components: {
    Empty,
    PageNum,
    courseTableMore,
  },
  mixins: [List],
  data: function () {
    return {
      // 页面检索数据
      retrievalData: {
        // 课程名称
        courseName: "",
        // 发布状态
        welfareCourseStatus: "",
      },
      // 发布状态 - 下拉数据
      welfareCourseStatusList: [],
    };
  },
  mounted() {},
  created() {
    this.getLevel();
  },
  methods: {
    // 获取 - 发布状态 - 下拉数据
    getLevel() {
      const certLevel = this.$setDictionary("PUBLIC_GOOD_STATUS", "list");
      for (const key in certLevel) {
        this.welfareCourseStatusList.push({
          value: key,
          label: certLevel[key],
        });
      }
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        courseName: this.retrievalData.courseName,
        welfareCourseStatus: this.retrievalData.welfareCourseStatus,
      };
      this.doFetch(
        {
          url: "/welfare/course/pageList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    // 关联课程 - 弹框
    relatedCourses() {
      this.$refs.courseTableMore.showPopUp();
    },
    // 关联课程回调
    courseTableMoreBack(ret) {
      let courseIds = [];
      let oldCourseIds = [];
      if (this.tableData.length > 0) {
        this.tableData.map((el1) => {
          oldCourseIds.push(el1.courseId);
        });
        ret.map((el) => {
          if (oldCourseIds.indexOf(el.courseId) === -1) {
            courseIds.push(el.courseId);
          }
        });
      } else {
        ret.map((el) => {
          courseIds.push(el.courseId);
        });
      }

      this.$post("/welfare/course/insert", { courseIds }, 3000, true, 2).then(
        (res) => {
          if (res.status == 0) {
            this.$message.success("关联成功");
            this.getData(-1);
          }
        }
      );
    },
    // 发布 / 回收
    releaseRecovery(row) {
      this.$post(
        "/welfare/course/modify/status",
        {
          welfareCourseId: row.welfareCourseId,
          welfareCourseStatus: row.welfareCourseStatus == "10" ? "20" : "10",
        },
        3000,
        true,
        2
      ).then((res) => {
        if (res.status == 0) {
          this.$message.success(
            row.welfareCourseStatus == "10" ? "发布成功" : "回收成功"
          );
          this.getData(-1);
        }
      });
    },
    // 删除
    deleteList(row) {
      this.$post(
        "/welfare/course/delete",
        {
          welfareCourseId: row.welfareCourseId,
        },
        3000,
        true,
        2
      ).then((res) => {
        if (res.status == 0) {
          this.$message.success("删除成功");
          this.getData(-1);
        }
      });
    },
  },
};
</script>

<style lang="less" scope>
</style>